import {
  Button,
  makeStyles,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  shorthands,
  tokens,
  typographyStyles,
} from "@fluentui/react-components";
import React from "react";
import { LinkIcon } from "@taskpane/pages/login/ui/link-icon";
import { CheckIcon } from "@taskpane/pages/login/ui/check-icon";
import { SparkleIcon } from "@taskpane/pages/login/ui/sparkle-icon";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "77vh",
  },
  auth: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  content: {
    textAlign: "center",
    ...typographyStyles.subtitle2Stronger,
  },
  advantages: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "12px",
    ...shorthands.padding("16px"),
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStroke1),
  },
  advantage: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  advantageIcon: {
    color: tokens.colorNeutralForeground4,
    width: "24px",
    height: "24px",
  },
  advantageContent: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  advantageContentTitle: {
    color: tokens.colorNeutralForeground4,
    ...typographyStyles.body1Strong,
  },
  advantageContentDescription: {
    color: tokens.colorNeutralForeground2,
    ...typographyStyles.caption1,
  },
  message: {
    ...shorthands.borderColor("#B6B9FF"),
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
});

export function Authorization({ onLogin, onSkip }: { onLogin: () => void; onSkip: () => void }) {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.auth}>
        <div className={styles.content}>Точность и актуальность правовых документов в вашем текстовом редакторе</div>
        <div className={styles.advantages}>
          <div className={styles.advantage}>
            <div className={styles.advantageIcon}>
              <LinkIcon />
            </div>
            <div className={styles.advantageContent}>
              <div className={styles.advantageContentTitle}>Ссылки на ГАРАНТ</div>
              <div className={styles.advantageContentDescription}>
                Добавляйте ссылки на систему ГАРАНТ, чтобы в любой момент обратиться к полному актуальному тексту
                нормативно-правового или нормативно-технического акта
              </div>
            </div>
          </div>
          <div className={styles.advantage}>
            <div className={styles.advantageIcon}>
              <CheckIcon />
            </div>
            <div className={styles.advantageContent}>
              <div className={styles.advantageContentTitle}>Проверка ссылок</div>
              <div className={styles.advantageContentDescription}>
                Проверяйте актуальность правовых и технических норм, упомянутых в вашем документе
              </div>
            </div>
          </div>
        </div>
        <MessageBar className={styles.message} shape={"rounded"} icon={<SparkleIcon />}>
          <MessageBarBody>
            <MessageBarTitle>Нет доступа к системе?</MessageBarTitle>
            <div>Получите пробный доступ на 3 дня бесплатно</div>
          </MessageBarBody>
          <MessageBarActions>
            <Button
              as="a"
              href="https://go.garant.ru/?utm_source=checkdoc_plug-in&utm_medium=landing&utm_campaign=lead-from-plugin#form"
            >
              Получить пробный доступ
            </Button>
          </MessageBarActions>
        </MessageBar>
      </div>
      <div className={styles.actions}>
        <Button appearance="primary" onClick={onLogin}>
          Войти
        </Button>
        <Button onClick={onSkip}>Продолжить без авторизации</Button>
      </div>
    </div>
  );
}
