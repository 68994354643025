import { analysing } from "@taskpane/plugin/api/endpoints/public/analyse/analysing";
import { getBodyOoxml } from "@taskpane/plugin/word/content/get-body-ooxml";
import { setBodyOoxml } from "@taskpane/plugin/word/content/set-body-ooxml";
import { getStatus } from "@taskpane/plugin/api/endpoints/public/analyse/get-status";
import { getResult } from "@taskpane/plugin/api/endpoints/public/analyse/get-result";

const MAX_REQUESTS_LIMIT = 200;
const INTERVAL_BETWEEN_REQUESTS = 3000;

function awaitResult(analyseId: string) {
  let delay = INTERVAL_BETWEEN_REQUESTS;

  return new Promise((resolve, reject) => {
    let count = 0;
    let timerId = setInterval(async () => {
      count += 1;
      try {
        const statusResult = await getStatus(analyseId);
        if (statusResult.hasError === true) {
          clearInterval(timerId);
          reject(new Error("Возникла ошибка во время анализа"));
        }
        if (statusResult.active === false) {
          clearInterval(timerId);
          resolve("");
        }
      } catch (error) {
        reject(error);
      }
      if (count === MAX_REQUESTS_LIMIT) {
        clearInterval(timerId);
        reject(
          new Error(
            "К сожалению, за отведенное время сервису не удалось проставить ссылки. Вы можете попробовать разбить список на части и запустить процесс заново или обратиться в поддержку"
          )
        );
      }
    }, delay);
  });
}

export async function analyse() {
  const content = await getBodyOoxml();

  const analyseResult = await analysing(content);
  await awaitResult(analyseResult.id);
  const result = await getResult(analyseResult.id);

  await setBodyOoxml(result.content);
}
