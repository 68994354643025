import React from "react";
import { makeStyles, mergeClasses, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    ...shorthands.padding("20px"),
  },

  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("20px"),
  },

  success: {
    color: tokens.colorStatusSuccessForeground1,
    backgroundColor: tokens.colorStatusSuccessBackground1,
  },

  info: {
    color: "#616161",
    backgroundColor: "#F5F5F5",
  },

  error: {
    color: tokens.colorStatusDangerForeground1,
    backgroundColor: tokens.colorStatusDangerBackground1,
  },

  icon: {
    width: "80px",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.borderRadius("16px"),
  },

  title: {
    textAlign: "center",
    ...typographyStyles.subtitle2,
  },

  text: {
    textAlign: "center",
    ...typographyStyles.body1,
  },
});

export function StatusScreen({
  variant,
  icon,
  title,
  text,
  additionalText,
}: {
  variant: "success" | "info" | "error";
  icon: React.ReactElement;
  title: string;
  text?: string;
  additionalText?: string;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div
          className={mergeClasses(
            styles.icon,
            {
              success: styles.success,
              info: styles.info,
              error: styles.error,
            }[variant]
          )}
        >
          {icon}
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.text}>{additionalText}</div>
      </div>
    </div>
  );
}
