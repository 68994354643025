import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Container, MainLayout, BackNavigation } from "@taskpane/shared/layout";
import { useActions } from "@taskpane/infrastructure/actions";
import { analyse } from "@taskpane/plugin/action/analyse/analyse";
import { Info, Loader } from "@taskpane/shared/ui";
import { Checkmark } from "@taskpane/plugin/ui/status-screen/icons";
import { formatter } from "@taskpane/shared/formatter";
import { getPlurals } from "@taskpane/plugin/shared/plurals";
import { StatusScreen } from "@taskpane/plugin/ui/status-screen";
import { all as allAnalytics } from "@taskpane/plugin/action/analytics/all";

export default function Page(): React.JSX.Element {
  const navigate = useNavigate();
  const { addAction, clearActions } = useActions();
  const { isSuccess, isError } = useQuery({
    queryKey: "/analyse/analyse",
    queryFn: async () => await analyse(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const { data: result = null } = useQuery({
    queryKey: "/all-analytics",
    queryFn: async () => await allAnalytics(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: isSuccess,
    onSuccess: (result) => {
      if (result.unknownLinksCount > 0) {
        addAction({
          id: "unknown-links",
          title: "Уточнить",
          onClick: () => navigate("/unknown-links"),
        });
      }
      addAction({
        id: "open-documents",
        title: "Открыть список документов",
        onClick: () => navigate("/documents"),
      });
    },
  });

  useEffect(() => {
    return () => {
      clearActions();
    };
  }, []);

  if (isError) {
    return (
      <MainLayout backNavigation={<BackNavigation title={"К панели управления"} onClick={() => navigate("/")} />}>
        <StatusScreen
          variant="info"
          icon={<Info />}
          title={"Ссылки не проставлены"}
          text={
            "К сожалению, за отведенное время сервису не удалось проставить ссылки. Вы можете попробовать разбить список на части и запустить процесс заново или обратиться в поддержку"
          }
        />
      </MainLayout>
    );
  }

  if (result === null) {
    return (
      <MainLayout backNavigation={<BackNavigation title={"К панели управления"} onClick={() => navigate("/")} />}>
        <Loader label={"Ставим ссылки..."} />
      </MainLayout>
    );
  }

  const { linksCount, docsCount, unknownLinksCount } = result;

  if (linksCount === 0) {
    return (
      <MainLayout>
        <Container>
          <StatusScreen
            variant="success"
            icon={<Checkmark />}
            title={"В тексте Вашего документа ссылки не обнаружены"}
          />
        </Container>
      </MainLayout>
    );
  }

  const linksText = `${linksCount} ${formatter.plural(linksCount, getPlurals("links"))}`;
  const docsText = `${docsCount} ${formatter.plural(docsCount, getPlurals("documents"))}`;
  const unknownText =
    unknownLinksCount > 0 ? "Для проставления ссылок на остальные документы требуется ваше уточнение" : undefined;

  return (
    <MainLayout>
      <Container>
        <StatusScreen
          variant="success"
          icon={<Checkmark />}
          title={`Проставлено ${linksText} на ${docsText}`}
          text={unknownText}
        />
      </Container>
    </MainLayout>
  );
}
