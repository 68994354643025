import React from "react";
import { makeStyles, mergeClasses, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.borderWidth(0, 0, 0, "4px"),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralForeground3),
  },
  rootDefault: {
    ...shorthands.borderColor(tokens.colorNeutralForeground3),
  },
  rootPrimary: {
    ...shorthands.borderColor(tokens.colorBrandForeground1),
  },
  rootDanger: {
    ...shorthands.borderColor(tokens.colorStatusDangerForeground1),
  },
  rootWarning: {
    ...shorthands.borderColor(tokens.colorStatusWarningForeground1),
  },
  rootSuccess: {
    ...shorthands.borderColor(tokens.colorStatusSuccessForeground1),
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    ...shorthands.padding("2px", 0, 0, "8px"),
  },

  contentTitle: {
    ...typographyStyles.caption1,
  },

  contentQuantity: {
    color: tokens.colorNeutralForeground3,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
  },
  contentQuantityDefault: {
    color: tokens.colorNeutralForeground3,
  },
  contentQuantityPrimary: {
    color: tokens.colorBrandForeground1,
  },
  contentQuantityDanger: {
    color: tokens.colorStatusDangerForeground1,
  },
  contentQuantityWarning: {
    color: tokens.colorStatusWarningForeground1,
  },
  contentQuantitySuccess: {
    color: tokens.colorStatusSuccessForeground1,
  },
});

type ColorValiant = "default" | "primary" | "danger" | "warning" | "success";

export function AnalyticItem({
  variant = "default",
  title,
  quantity,
  fromTotal,
}: {
  variant?: ColorValiant;
  title: string;
  quantity: string;
  fromTotal?: string;
}) {
  const styles = useStyles();
  return (
    <div
      className={mergeClasses(
        styles.root,
        {
          default: styles.rootDefault,
          primary: styles.rootPrimary,
          danger: styles.rootDanger,
          warning: styles.rootWarning,
          success: styles.rootSuccess,
        }[variant]
      )}
    >
      <div className={styles.content}>
        <div className={styles.contentTitle}>{title}</div>
        <div
          className={mergeClasses(
            styles.contentQuantity,
            {
              default: styles.contentQuantityDefault,
              primary: styles.contentQuantityPrimary,
              danger: styles.contentQuantityDanger,
              warning: styles.contentQuantityWarning,
              success: styles.contentQuantitySuccess,
            }[variant]
          )}
        >
          {quantity}
          {fromTotal && <span className={styles.contentQuantityDefault}>/{fromTotal}</span>}
        </div>
      </div>
    </div>
  );
}
