import React from "react";
import { ActionsItem } from "@taskpane/shared/ui/action/actions-item";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
} from "@fluentui/react-components";

interface ConfirmDialogProps {
  onConfirm: () => void;
  triggerElement: React.ReactElement;
  children: React.ReactNode;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ onConfirm, triggerElement, children }) => {
  return (
    <Dialog modalType="alert">
      <DialogTrigger disableButtonEnhancement>{triggerElement}</DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Подтверждение действия</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="primary" onClick={onConfirm}>
                Подтвердить
              </Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Отменить</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export function ConfirmableActionsItem({
  icon,
  title,
  badge = undefined,
  onConfirm,
  confirmMessage,
}: {
  icon?: React.ReactElement;
  title: string;
  badge?: React.ReactNode;
  onConfirm: () => void;
  confirmMessage: React.ReactNode;
}): React.JSX.Element {
  return (
    <ConfirmDialog triggerElement={<ActionsItem icon={icon} title={title} badge={badge} />} onConfirm={onConfirm}>
      {confirmMessage}
    </ConfirmDialog>
  );
}
