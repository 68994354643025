import React from "react";
import { Tariff } from "@taskpane/plugin/ui/tariff";
import { useAuth } from "@taskpane/auth";
import { useTariff } from "@taskpane/pages/profile/account-level/use-tariff";

export default function Tariffs(): React.JSX.Element {
  const { user, isAuthenticated } = useAuth();
  const getUserTariff = () => {
    if (isAuthenticated) {
      return user?.isPremium ? "checkdoc" : "authorized";
    }
    return "basic";
  };

  const userTariff = getUserTariff();
  const { currentTariff, prev, next, allowPrev, allowNext } = useTariff(userTariff);

  return (
    <Tariff
      tariff={currentTariff}
      isActive={currentTariff === userTariff}
      allowPrev={allowPrev}
      allowNext={allowNext}
      onPrev={prev}
      onNext={next}
    />
  );
}
