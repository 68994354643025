import React from "react";
import { Link, makeStyles, tokens } from "@fluentui/react-components";
import { LineIcon } from "./line-icon";

const useStyles = makeStyles({
  container: {
    height: "48px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: tokens.colorNeutralBackground3,
    gap: "8px",
  },
});

export function FooterContent() {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div>+7 800 200-88-88</div>
      <LineIcon />
      <Link href="https://chkdoc.ru/premium/feedback">Обратная связь</Link>
    </div>
  );
}
