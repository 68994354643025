type Document = {
  status?: string;
  changed?: boolean | null;
  expireDate?: string | null;
};

type Result = "active" | "edited" | "expired" | "none";

export function check(document: Document): Result {
  if (document.status === "NO_ACTIVE") {
    return "expired";
  }

  if (document.status === "ACTIVE" && document.changed === true) {
    return "edited";
  }

  if (document.status === "ACTIVE") {
    return "active";
  }

  return "none";
}
