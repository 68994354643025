import React from "react";
import { makeStyles, Button, typographyStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    textAlign: "center",
    ...typographyStyles.body1Stronger,
    ...shorthands.padding(0, "10px"),
  },
  button: {
    minWidth: "50px",
  },
});

export function Navigation({
  currentTariff,
  allowPrev,
  allowNext,
  onPrev,
  onNext,
}: {
  currentTariff: string;
  allowPrev: boolean;
  allowNext: boolean;
  onPrev: () => void;
  onNext: () => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Button className={styles.button} onClick={onPrev} style={{ visibility: allowPrev ? "visible" : "hidden" }}>
        Назад
      </Button>
      <div className={styles.title}>{currentTariff}</div>
      <Button className={styles.button} onClick={onNext} style={{ visibility: allowNext ? "visible" : "hidden" }}>
        Далее
      </Button>
    </div>
  );
}
