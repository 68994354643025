import { useEffect } from "react";
import { useActions } from "@taskpane/infrastructure/actions";
import { Links } from "../model/links";

export function useShowDocumentsAction(links: Links, showDocuments: () => Promise<void>) {
  const { addAction, removeAction } = useActions();

  useEffect(() => {
    addAction({
      id: "show-documents",
      title: "Открыть список документов",
      variant: "secondary",
      sort: 1000,
      onClick: async () => {
        await showDocuments();
      },
    });
    return () => {
      removeAction("show-documents");
    };
  }, [links]);
}
