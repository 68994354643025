import { useState, useMemo } from "react";
import { check } from "../../service/document-status-checker";
import { Document } from "../../model/document";

export type FilterType = "title" | "status";

interface FilterCriteria {
  type: FilterType;
  value: string | string[];
}

export function useDocumentFilter(initialDocuments: Document[]) {
  const [criteria, setCriteria] = useState<FilterCriteria[]>([]);

  const filteredDocuments = useMemo(() => {
    return initialDocuments.filter((document) => {
      return criteria.every((criteria) => {
        if (criteria.type === "title") {
          return String(document.title).toLowerCase().trim().includes(String(criteria.value).toLowerCase().trim());
        }
        if (criteria.type === "status" && Array.isArray(criteria.value) && criteria.value.length > 0) {
          const statusChecks: { [key: string]: (document: Document) => boolean } = {
            active: (document) => check(document) === "active",
            edited: (document) => check(document) === "edited",
            expired: (document) => check(document) === "expired",
          };

          return criteria.value.some((value) => statusChecks[value](document));
        }
        return true;
      });
    });
  }, [initialDocuments, criteria]);

  const setFilterCriteria = (type: FilterType, value: string) => {
    const updatedCriteria = [...criteria.filter((c) => c.type !== type), { type, value }];
    setCriteria(updatedCriteria);
  };

  const removeFilterCriteria = (type: FilterType) => {
    setCriteria((prev) => prev.filter((criteria) => criteria.type !== type));
  };

  return { filteredDocuments, filterCriteria: criteria, setFilterCriteria, removeFilterCriteria };
}
