import React from "react";
import { formatter } from "@taskpane/shared/formatter";
import { DocumentStatus as SharedDocumentStatus } from "@taskpane/shared/ui/document-status";
import { documentStatusChecker } from "@taskpane/plugin/document";

type Document = {
  status?: string | null;
  changed?: boolean | null;
  expireDate?: string | null;
};

export function DocumentStatus({
  document,
  shape,
}: {
  document: Document;
  shape?: "square" | "rounded";
}): React.JSX.Element {
  if (documentStatusChecker.check(document) === "expired") {
    return (
      <SharedDocumentStatus
        variant={"expired"}
        shape={shape}
        expireDate={formatter.date(new Date(document.expireDate), "dd.MM.yyyy")}
      />
    );
  }
  if (documentStatusChecker.check(document) === "edited") {
    return <SharedDocumentStatus variant={"edited"} shape={shape} />;
  }
  if (documentStatusChecker.check(document) === "active") {
    return <SharedDocumentStatus variant={"active"} shape={shape} />;
  }
  return undefined;
}
