import React from "react";
import { makeStyles, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  title: {
    ...typographyStyles.subtitle2,
  },
  description: {
    ...typographyStyles.body1,
  },
});

export function GuestProfile() {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.title}>Войдите в аккаунт</div>
      <div className={styles.description}>Чтобы можно было использовать больше функций плагина</div>
    </div>
  );
}
