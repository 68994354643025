import { selectLink } from "@taskpane/plugin/word/link/select-link";
import { setLinks as setAllLinks } from "@taskpane/plugin/action/analyse/set-links";
import { removeAllLinksByUrl } from "@taskpane/plugin/word/link/remove-all-links-by-url";
import { Link } from "../model/link";
import { SelectedDocument } from "../model/selected-document";
import { useDocuments } from "./use-documents";
import { useSelectedLink } from "./use-selected-link";
import { useActions } from "./use-actions";
import { Links } from "../model/links";
import { useConfirmAction } from "./use-confirm-action";
import { useClearAction } from "./use-clear-action";
import { useLinks } from "./use-links";
import { useLinkFilter } from "./use-link-filter";
import { useShowDocumentsAction } from "@taskpane/plugin/links/hook/use-show-documents-action";
import { useNavigate } from "react-router-dom";

export function useLinksManager(initialLinks: Links) {
  const { links, setLinks, clearLinks } = useLinks(initialLinks);
  const { documents, setDocument, clearDocuments } = useDocuments();
  const { link, setLink } = useSelectedLink();
  const { filteredLinks, setFilterCriteria } = useLinkFilter(links);
  const { addAction, removeAction } = useActions();
  const navigate = useNavigate();

  const confirm = async () => {
    await setAllLinks(documents);
    setLinks(links.filter((link) => !documents.some((document) => document.oldUrl === link.url)));
    clearDocuments();
  };

  const clear = async () => {
    await removeAllLinksByUrl(links.map((link) => ({ url: link.url })));
    clearDocuments();
    clearLinks();
  };

  const removeLink = async (link: Link) => {
    await removeAllLinksByUrl([link]);
    setLinks(links.filter((existing) => link.url !== existing.url));
    setDocument(documents.filter((document) => document.oldUrl !== link.url));
  };

  useConfirmAction(documents, link, confirm);
  useClearAction(links, link, clear);
  useShowDocumentsAction(links, async () => navigate("/documents"));

  const chooseDocument = ({ oldId, oldUrl, newUrl, newUrlTitle }: SelectedDocument) => {
    setDocument([
      ...documents.filter((existing) => existing.oldUrl !== oldUrl),
      {
        oldId,
        oldUrl,
        newUrlTitle,
        newUrl,
      },
    ]);
    addAction({
      id: "set-link",
      title: "Установить ссылку",
      onClick: () => {
        setLink(null);
        removeAction("set-link");
      },
    });
  };

  const cancelChooseDocument = () => {
    setLink(null);
    removeAction("set-link");
  };

  const chooseLink = async (link: Link) => {
    await selectLink(link.url);
    setLink(link);
  };

  return {
    links,
    filteredLinks,
    setFilterCriteria,
    link,
    documents,
    chooseDocument,
    cancelChooseDocument,
    chooseLink,
    removeLink,
  };
}
