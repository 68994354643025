import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="36" height="36" fill="white" />
      <g clipPath="url(#clip0_2135_15593)">
        <mask id="mask0_2135_15593" maskUnits="userSpaceOnUse" x="3" y="9" width="30" height="20">
          <path d="M3 9H33V29H3V9Z" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2135_15593)">
          <path d="M27.1069 9L19.6067 9L23.3568 12.6844L27.1069 9Z" fill="#047B6D" />
          <path
            d="M18.8031 9.78952L23.3568 14.2634L27.9105 9.78952L33 14.7898L19.2947 28.2549C18.2835 29.2484 16.644 29.2484 15.6328 28.2549L7.82052 20.5796L18.8031 9.78952Z"
            fill="#047B6D"
          />
          <path
            d="M6.21331 10.579L11.3028 15.5793L7.01691 19.7901L3.75841 16.5887C2.7472 15.5952 2.7472 13.9844 3.75841 12.9909L6.21331 10.579Z"
            fill="#047B6D"
          />
          <path
            d="M29.4002 19H21.1716C20.6036 19 20.1431 19.4477 20.1431 20V28C20.1431 28.5523 20.6036 29 21.1716 29H29.4002C29.9683 29 30.4288 28.5523 30.4288 28V20C30.4288 19.4477 29.9683 19 29.4002 19Z"
            fill="#41A5EE"
          />
          <path
            d="M22.5923 27.5476L21.1714 21.8333H22.5203L23.1139 24.5759L23.4466 26.1641H23.4736L23.8693 24.5759L24.5617 21.8333H26.0546L26.756 24.5759L27.1427 26.1641H27.1697L27.5024 24.5759L28.105 21.8333H29.4L27.9431 27.5476H26.3873L25.5959 24.4531L25.2902 23.2005H25.2632L24.9484 24.4531L24.1571 27.5476H22.5923Z"
            fill="black"
            fillOpacity="0.08"
          />
          <path
            d="M22.5923 26.7143L21.1714 21H22.5203L23.1139 23.7425L23.4466 25.3307H23.4736L23.8693 23.7425L24.5617 21H26.0546L26.756 23.7425L27.1427 25.3307H27.1697L27.5024 23.7425L28.105 21H29.4L27.9431 26.7143H26.3873L25.5959 23.6197L25.2902 22.3672H25.2632L24.9484 23.6197L24.1571 26.7143H22.5923Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2135_15593">
          <rect width="30" height="20" fill="white" transform="translate(3 9)" />
        </clipPath>
      </defs>
    </svg>
  );
}, "GarantLogo");

export const GarantLogo = bundleIcon(Icon, Icon);
