import { useQuery } from "react-query";
import { getLinks } from "@taskpane/plugin/action/analyse/get-links";

export function usePublicLinks() {
  const { data = [], isLoading } = useQuery({
    queryKey: "/analyse/get-links",
    queryFn: async () => await getLinks(),
    cacheTime: 0,
  });
  return { data, isLoading };
}
