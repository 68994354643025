import React from "react";
import { Link as LinkUI, makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "600px",
    gap: "12px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  infoTitle: {
    ...typographyStyles.subtitle2Stronger,
  },
  infoDescription: {
    ...typographyStyles.body1,
  },

  links: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderTop: "1px solid",
    ...shorthands.borderColor(tokens.colorNeutralStroke1),
    paddingTop: "12px",
  },
});

export function GuestState(): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <div className={styles.infoTitle}>Выбор версии</div>
        <div className={styles.infoDescription}>
          Выбор версии проставляемых ссылок доступен только для пользователей с тарифами “Авторизованный” и “Чекдок
          премиум”
        </div>
      </div>
      <div className={styles.links}>
        <Link to="/profile/account-level">Тарифы</Link>
        <LinkUI href="https://chkdoc.ru/premium">Подробнее о премиум пакете</LinkUI>
      </div>
    </div>
  );
}
