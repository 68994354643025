import { useQuery } from "react-query";
import { getPrivateLinks } from "@taskpane/plugin/action/analyse/get-private-links";

export function usePrivateLinks() {
  const { data = [], isLoading } = useQuery({
    queryKey: "/analyse/get-private-links",
    queryFn: async () => await getPrivateLinks(),
    cacheTime: 0,
  });
  return { data, isLoading };
}
