import React, { useState } from "react";

interface Action {
  id: string;
  title: string;
  variant?: "secondary" | "primary" | "outline" | "subtle" | "transparent";
  sort?: number;
  onClick: () => void;
}

interface ActionContextType {
  actions: Action[];
  addAction: (action: Action) => void;
  removeAction: (id: string) => void;
  clearActions: () => void;
}

const ActionContext = React.createContext<ActionContextType | null>(null);
ActionContext.displayName = "ActionContext";

const ActionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [actions, setActions] = useState<Action[]>([]);

  const addAction = (action: Action) => {
    setActions((prevActions) => {
      const existingActionIndex = prevActions.findIndex((a) => a.id === action.id);
      if (existingActionIndex !== -1) {
        return [...prevActions.slice(0, existingActionIndex), action, ...prevActions.slice(existingActionIndex + 1)];
      }
      return [...prevActions, action];
    });
  };

  const removeAction = (id: string) => {
    setActions((prevActions) => prevActions.filter((action) => action.id !== id));
  };

  const clearActions = () => {
    setActions([]);
  };

  return (
    <ActionContext.Provider value={{ actions, addAction, removeAction, clearActions }}>
      {children}
    </ActionContext.Provider>
  );
};

export { ActionsProvider, ActionContext };
