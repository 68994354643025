import React from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "@fluentui/react-components";
import { Actions, ActionsItem } from "@taskpane/shared/ui/action";
import { UserProfile } from "@taskpane/plugin/ui/profile/user-profile";
import { useAuth } from "@taskpane/auth";
import { GuestProfile } from "@taskpane/plugin/ui/profile/guest-profile";
import { NoAccess } from "@taskpane/plugin/ui/profile/no-access";
import { Button } from "@taskpane/plugin/ui/fragment";
import { GuestContainer } from "@taskpane/plugin/ui/profile/guest-container";

export function Profile(): React.JSX.Element {
  const navigate = useNavigate();
  const { user, isAuthenticated, signOut } = useAuth();
  const isPremium: boolean = user?.isPremium ?? false;

  return (
    <div>
      <Actions>
        {user !== null ? <UserProfile user={user} onLogout={signOut} /> : <GuestProfile />}
        <ActionsItem
          title={"Версия проставляемых ссылок"}
          badge={"Интернет-версия"}
          onClick={() => (!isAuthenticated ? navigate("/profile/link-versions") : "")}
        />
        <ActionsItem
          title={"Уровень аккаунта"}
          badge={
            <Badge appearance={isPremium ? "filled" : "outline"} shape="rounded">
              {user === null ? "Базовый" : isPremium ? "ЧекДок Премиум" : "Авторизованный"}
            </Badge>
          }
          onClick={() => navigate("/profile/account-level")}
        />
        <ActionsItem title={"Обратная связь"} onClick={() => window.open("https://chkdoc.ru/premium/feedback")} />
        <ActionsItem title={"О системе"} onClick={() => window.open("https://chkdoc.ru/premium/about", "_blank")} />
      </Actions>
      {user === null && (
        <GuestContainer>
          <NoAccess />
          <Button onClick={() => navigate("/login")}>Войти</Button>
        </GuestContainer>
      )}
    </div>
  );
}
