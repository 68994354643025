import React from "react";
import { useAlertContext } from "@taskpane/infrastructure/alert";
import { errorHandler } from "@taskpane/shared/error-handler/error-handler";
import { QueryClient, QueryClientProvider } from "react-query";

export function QueryClientWithAlertsProvider({ children }: { children: React.ReactNode }) {
  const alertContext = useAlertContext();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        useErrorBoundary: false,
        retry: false,
        onError: (error: any) => {
          try {
            errorHandler(error);
          } catch (error) {
            alertContext.error(error.message);
          }
        },
      },
      mutations: {
        onError: (error: any) => {
          try {
            errorHandler(error);
          } catch (error) {
            alertContext.error(error.message);
          }
        },
      },
    },
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
