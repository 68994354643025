import React, { useEffect } from "react";
import { Container, MainLayout, BackNavigation } from "@taskpane/shared/layout";
import { Loader } from "@taskpane/shared/ui";
import { remove } from "@taskpane/plugin/action/documents/remove";
import { Fragment, Fragments, EmptyState as FragmentEmptyState } from "@taskpane/plugin/ui/fragment";
import { removeReference as removeReferenceAction } from "@taskpane/plugin/action/documents/remove-reference";
import { selectByLinkAndIndex } from "@taskpane/plugin/word";
import { useNavigate } from "react-router-dom";
import { removeDocument } from "@taskpane/plugin/action/documents/remove-document";
import { useActions } from "@taskpane/infrastructure/actions";
import {
  useDocumentState,
  DocumentNavigation,
  Documents,
  EmptyState,
  DocumentItem,
  DocumentStatus,
  useDocuments,
  Analytics,
} from "@taskpane/plugin/document";
import { DocumentFilter } from "@taskpane/plugin/document/ui/document-filter";
import { useScrollContext } from "@taskpane/shared/scroll-restoration";
import { useAuth } from "@taskpane/auth";

export default function Page(): React.JSX.Element {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { addAction, removeAction } = useActions();
  const { data = [], isLoading, refetch, isVerified } = useDocuments();
  const { setScrollState } = useScrollContext();

  const {
    documents,
    filteredDocuments,
    setFilterCriteria,
    document,
    setCurrentDocument,
    resetDocument,
    goToNextDocument,
    goToPrevDocument,
    removeEntry,
    toggleReference,
    goToNextReference,
    goToPreviousReference,
    removeReference,
  } = useDocumentState(data);

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      addAction({
        id: "analyse",
        title: "Проставить ссылки",
        onClick: () => navigate("/analyse"),
      });
    }
    return () => {
      removeAction("analyse");
    };
  }, [isLoading, data.length]);

  if (isLoading) {
    return <Loader />;
  }

  if (documents.length === 0) {
    return (
      <MainLayout>
        <Container>
          <EmptyState />
        </Container>
      </MainLayout>
    );
  }

  if (document !== null) {
    return (
      <MainLayout
        backNavigation={
          <BackNavigation
            title={"К списку документов"}
            onClick={() => {
              resetDocument();
              setScrollState("/documents", false);
            }}
          />
        }
      >
        <DocumentNavigation title={document.title} onPrev={goToPrevDocument} onNext={goToNextDocument} />
        <DocumentStatus document={document} shape={"square"} />
        {document.entries.length === 0 ? (
          <FragmentEmptyState />
        ) : (
          <Container>
            <Fragments>
              {document.entries
                .sort((prev, next) => prev.entryId - next.entryId)
                .map((entry) => {
                  const referenceIndex = entry.references.findIndex((reference) => reference.isSelected);
                  const referenceNumber = referenceIndex !== -1 ? referenceIndex : 0;
                  return (
                    <Fragment
                      key={entry.entryId}
                      title={entry.entryId === 0 ? "Начало  документа" : ""}
                      content={entry.references[referenceNumber]?.content}
                      status={entry.changed === true ? "updated" : undefined}
                      citationCount={referenceNumber + 1}
                      citationTotalCount={entry.references.length}
                      selected={entry.references[referenceNumber]?.isSelected ?? false}
                      onContentSelect={async () => {
                        toggleReference(entry.id, referenceNumber);
                        await selectByLinkAndIndex(entry.references[referenceNumber].url, referenceNumber);
                      }}
                      onRemoveLink={async () => {
                        removeReference(entry.id, referenceNumber);
                        await removeReferenceAction(entry.references[referenceNumber].url, referenceNumber);
                      }}
                      onRemoveAllReference={async () => {
                        removeEntry(entry.id);
                        await remove(entry.url);
                      }}
                      onPrevReference={async () => {
                        goToPreviousReference(entry.id);
                        await selectByLinkAndIndex(entry.references[referenceNumber].url, referenceNumber - 1);
                      }}
                      onNextReference={async () => {
                        goToNextReference(entry.id);
                        await selectByLinkAndIndex(entry.references[referenceNumber].url, referenceNumber + 1);
                      }}
                    />
                  );
                })}
            </Fragments>
          </Container>
        )}
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Container>
        <Documents>
          <DocumentFilter
            setFilterCriteria={setFilterCriteria}
            isFilter={user?.isPremium ?? false}
            isVerified={isVerified}
          />
          <Analytics />
          {filteredDocuments.map((document, index) => {
            return (
              <DocumentItem
                key={document.id}
                title={document.title}
                status={<DocumentStatus document={document} />}
                linksCount={document.linksCount}
                fragmentsCount={document.fragmentsCount}
                onShowFragments={async () => {
                  setScrollState("/documents", false);
                  setCurrentDocument(index);
                  await selectByLinkAndIndex(document.entries[0].url, 0);
                }}
                onRemove={async () => {
                  await removeDocument(document.url);
                  refetch();
                }}
              />
            );
          })}
        </Documents>
      </Container>
    </MainLayout>
  );
}
