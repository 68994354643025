import React from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout, BackNavigation, Container } from "@taskpane/shared/layout";
import { GuestState } from "@taskpane/plugin/ui/link-version";

export default function Page(): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <MainLayout backNavigation={<BackNavigation title={"К профилю"} onClick={() => navigate("/profile")} />}>
      <Container>
        <GuestState />
      </Container>
    </MainLayout>
  );
}
